<template>
    <div class="landing-bg full-page">
        <navbar/>
        <template>
            <div class="landing-hero">
                <h1 class="login-small-title">LA PLATEFORME DE CANDIDATURE ET D’INSCRIPTION AU CYCLE DOCTORAL</h1>
                <h2 class="login-title text-bold">ESPACE D'Inscription</h2>

                <div class="ui grid">
                    <div class="ui twelve wide computer  sixteen wide mobile column form mt-2 ml-1">
                        <div class="ui stackable grid ">
                            <div class="ui six wide column">
                                <div class="field login-field">
                                    <label>Nom</label>
                                    <input type="text" v-model.trim="$v.nom.$model" placeholder="Nom ...">
                                </div>

                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.nom.$error">
                                    <p> <i class="info circle icon"></i> Le nom est obligatoire </p>
                                </div>

                            </div>
                            <div class="ui six wide column">
                                <div class="field login-field">
                                    <label>Prénom</label>
                                    <input type="text" v-model.trim="$v.prenom.$model" placeholder="Prénom ...">
                                </div>

                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.prenom.$error">
                                    <p> <i class="info circle icon"></i> Le prénom est obligatoire </p>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="ui twelve wide computer sixteen wide mobile column form ml-1">
                        <div class="ui stackable grid">
                            <div class="ui six wide column">
                                <div class="field login-field">
                                    <label>E-mail</label>
                                    <input type="email" v-model.trim="$v.email.$model" placeholder="E-mail ...">
                                </div>

                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.email.$error">
                                    <p v-if="!$v.email.required"> <i class="info circle icon"></i> E-Mail est obligatoire </p>

                                    <p v-if="!$v.email.email"> <i class="info circle icon"></i> E-Mail est Invalide </p>
                                </div>
                            </div>

                            <div class="ui six wide column">
                                <div class="field login-field">
                                    <label>Nationalité</label>
                                    <v-select ref="selectInputJury" v-model="$v.origine.$model" placeholder="Nationalité ..."
                                              :options="origineOptions" class="v-select-login" :reduce="item => item._id"
                                              :clearable="false"
                                    />
                                </div>

                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.origine.$error">
                                    <p> <i class="info circle icon"></i> Nationalité est obligatoire </p>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="ui twelve wide computer sixteen wide mobile column form ml-1">
                        <div class="ui stackable grid">

                            <div class="ui six wide column" v-if="origine === 'marocain'">
                                <div class="field login-field">
                                    <label>CIN</label>
                                    <input type="text" v-model.trim="$v.cnie.$model" placeholder="La carte d'identité nationale...">
                                </div>

                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.cnie.$error">
                                    <p> <i class="info circle icon"></i> CIN est obligatoire </p>
                                </div>
                            </div>

                            <div class="ui six wide column" v-else-if="origine === 'etrangere'">
                                <div class="field login-field">
                                    <label>N° du Passeport</label>
                                    <input type="email" v-model.trim="$v.cnie.$model" placeholder="N° du passeport ...">
                                </div>

                                <!-- error message -->
                                <div class="ui negative message" v-if="$v.cnie.$error">
                                    <p> <i class="info circle icon"></i> Passeport est obligatoire </p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="ui twelve wide column ">
                        <div class="flex m-1">
                            <button class="login-button bg-orange "
                                    @click="onInscriptionClick" :disabled="isBusy"
                            >
                                S'inscrire
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import navbar from "@/components/landingNav.vue"
    import {email, required} from 'vuelidate/lib/validators'

    export default {
        name: 'inscription',
        components: {
            navbar,
        },
        data() {
            return {
                nom: '',
                prenom: '',
                cnie: '',
                email: '',
                origine: null,
                isBusy: false,
                origineOptions: [
                    {_id: "marocain", label: "Marocaine"},
                    {_id: "etrangere", label: "Etrangère"}
                ]
            }
        },
        validations(){
            return {
                nom: {required},
                prenom: {required},
                cnie: {required},
                email: {required, email},
                origine: {required}
            }
        },
        methods: {
            onInscriptionClick() {
                console.log('onLoginClick.');

                if (this.isBusy) return;

                this.$v.$touch();
                if (this.$v.$invalid)
                    return;

                this.isBusy = true

                this.$store.dispatchAsync(this.$SHARED.services.inscription, {
                    nom: this.nom,
                    prenom: this.prenom,
                    email: this.email.toLowerCase(),
                    cnie: this.cnie,
                }).then((data) => {
                    this.$toast.success(this.$SHARED.messages.user.create_succeeded, {duration: 20000})
                    this.$router.push({name: 'landing'});

                })
                    .finally(() => this.isBusy = false)
            }
        }
    }

</script>

<style scoped>
    .landing-bg {
        background-image: url("../assets/images/bg_landing.jpg") !important;
        background-size: cover;
        /* height: 100%; */
        /* background-color: var(--main-color-red); */
    }

    .full-page {
        /* display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center; */
        height: 100vh;
        width: 100vw;
    }

    .landing-hero {
        margin-top: 6em;
        padding: 0em 2em;
    }

    .login-small-title {
        font-size: 1.5em;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 1;
        color: var(--main-color-blue);
    }

    .login-title {
        font-size: 3.3em;
        font-weight: 400 !important;
        text-transform: uppercase;
        line-height: 1;
        color: var(--main-color-blue);
    }

    .login-field > label {
        font-weight: 300 !important;
        color: var(--main-color-blue) !important;
        font-size: 1.5em !important;
    }

    .login-field > input {
        color: var(--main-color-blue);
        padding: 1em 0.5em !important;
        background-color: #F4F9FB !important;
        border: 2px solid #0d458472 !important;
        border-radius: 0px !important;
    }

    .login-field > input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: rgba(13, 68, 132, 0.5);
    }

    .forgot-pass {
        color: var(--main-color-blue);
        text-decoration: underline;
        margin-top: 0.5em;
        font-weight: 600;
        cursor: pointer;
    }

    .login-button {
        padding: 0.75em 2em;
        background-color: var(--main-color-blue);
        color: #FFFFFF;
        font-weight: 700;
        font-size: 1.5em;
        /* text-transform: uppercase; */
        cursor: pointer;
        line-height: 1;
        border: none;
    }
    @media all and (max-width:700px){
		.login-title{
			font-size: 2.5em;
			text-align: center;
		}
        .login-small-title {
            text-align: center;
        }
    }
</style>
